import React, { useState } from "react";
import Modal, { Styles } from "react-modal";
import styled from "styled-components";
import { useHasMounted } from "../../hooks/useHasMounted";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CloudinaryImage } from "../../utils/CloudinaryImage";
import { breakpoint, marginPercent } from "../Layout";

export interface IPhotoWithViewerProps {
	src: string;
	credit?: string | null;
}

const PhotoImg = styled.img`
	margin: 0;
	padding: 0;
	cursor: pointer;
`;

interface ILargePhotoImgProps {
	isPlaceholder: boolean;
}

const LargePhotoImg = styled.img<ILargePhotoImgProps>`
	margin: 0;
	padding: 0;
	width: 100%;
	cursor: pointer;

	transition-duration: 0.5s;
	transition-property: filter;

	filter: ${props => props.isPlaceholder ? "blur(5px)" : "none"};
`;

const PhotoCredit = styled.p`
	margin: 0 20px 0 0;
	font-size: 12px;
	text-align: right;
`;

const modalStyle: Styles = {
	content: {
		border: undefined,
		borderRadius: undefined,
		outline: undefined,
		background: undefined,
		padding: undefined,
		inset: undefined,
		width: "90%",
		maxHeight: "100vh",
	},
	overlay: {
		background: "hsla(0, 0%, 0%, 0.75)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	}
};

export const PhotoWithViewer: React.FC<IPhotoWithViewerProps> = ({ src, credit }) => {
	const { width, devicePixelRatio } = useWindowSize(Number.MAX_SAFE_INTEGER, 0);
	const [modalVisible, setModalVisible] = useState<boolean>(false);
	const [loadCount, setLoadCount] = useState<number>(0);
	const hasMounted = useHasMounted();

	const fullUrlWidthPercentage = 100 - (marginPercent * 2);
	const fullUrlWidth = Math.round(width * (fullUrlWidthPercentage / 100));
	const fullUrl = CloudinaryImage.autoUrl(src, fullUrlWidth);

	const itemsPerRow = Math.floor((width * devicePixelRatio) / breakpoint);
	const smallUrlWidth = (width > breakpoint) ? Math.round((width / devicePixelRatio) / itemsPerRow) : width;
	const smallUrl = CloudinaryImage.autoUrl(src, smallUrlWidth, smallUrlWidth);

	if (!hasMounted) {
		return <div></div>;
	}

	const handleClick = () => {
		setModalVisible(true);
	};

	return (
		<>
			<PhotoImg src={smallUrl} onClick={() => handleClick()} />
			{<Modal style={modalStyle} isOpen={modalVisible} shouldCloseOnOverlayClick={true} onRequestClose={() => setModalVisible(false)}>
				<LargePhotoImg onClick={() => setModalVisible(false)} src={smallUrl} isPlaceholder={loadCount < 2} srcSet={`${fullUrl} ${fullUrlWidth}w`} onLoad={() => setLoadCount(loadCount + 1)} />
				{credit && <PhotoCredit>{credit}</PhotoCredit>}
			</Modal>}
		</>

	);
};